@use 'mixins/buttons';
@use 'mixins/vendor-prefixes';
@use 'variables';

body {
  color: variables.$black-color;
  font-size: variables.$font-size-base;
  font-family: variables.$sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: variables.$white-color;
}
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general,
.tag,
.tag [data-role='remove'],
.animation-transition-general {
  @include vendor-prefixes.transition(
    variables.$general-transition-time,
    variables.$transition-ease
  );
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include vendor-prefixes.transition(
    variables.$fast-transition-time,
    variables.$transition-ease
  );
}

.dropdown-toggle[aria-expanded='true']:after,
a[data-toggle='collapse'][aria-expanded='true'] .caret,
.card-collapse .card a[data-toggle='collapse'][aria-expanded='true'] i,
.card-collapse .card a[data-toggle='collapse'].expanded i {
  @include buttons.rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: variables.$white-bg;

  & + .button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.offline-doc {
  .navbar.navbar-transparent {
    padding-top: 25px;
    border-bottom: none;

    .navbar-minimize {
      display: none;
    }
    .navbar-brand,
    .collapse .navbar-nav .nav-link {
      color: variables.$white-color !important;
    }
  }
  .footer {
    z-index: 3 !important;
  }
  .page-header {
    .container {
      z-index: 3;
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.5);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.fixed-plugin {
  .dropdown-menu li {
    padding: 2px !important;
  }
}

// badge color

.badge {
  &.badge-default {
    @include vendor-prefixes.badge-color(variables.$default-color);
  }
  &.badge-primary {
    @include vendor-prefixes.badge-color(variables.$primary-color);
  }
  &.badge-info {
    @include vendor-prefixes.badge-color(variables.$info-color);
  }
  &.badge-success {
    @include vendor-prefixes.badge-color(variables.$success-color);
  }
  &.badge-warning {
    @include vendor-prefixes.badge-color(variables.$warning-color);
  }
  &.badge-danger {
    @include vendor-prefixes.badge-color(variables.$danger-color);
  }
  &.badge-neutral {
    @include vendor-prefixes.badge-color(variables.$white-color);
    color: inherit;
  }
}

.card-user {
  form {
    .form-group {
      margin-bottom: 20px;
    }
  }
}
