/* You can add global styles to this file, and also import other style files */
@use 'paper-dashboard/_variables.scss';

body,
html {
  overflow-x: hidden;
  height: 100%;
  background-color: #f4f3ef;
}

button:focus,
a:focus {
  outline: none !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: 50%;
  margin: 0;
  background-color: #6e96cc;
  color: #fff;
}

.modal-open .modal {
  z-index: 1060;
}

ngx-select-dropdown {
  min-width: 200px;
}

.readonly {
  background-color: #e3e3e3 !important;
  color: #66615b;
}

.form-control[readonly] {
  background-color: #fff;
  cursor: text;
}

.ast {
  color: red !important;
  margin-left: 3px;
}

.dropdown {
  z-index: 1060 !important;
}

.ngx-dropdown .ngx-menu {
  padding: 10px;
}

.ngx-dropdown-button {
  min-height: 40px !important;
}

.ngx-table__table--normal > thead > tr > th {
  padding: 10px 40px 10px 0;
}

.ngx-table__table--normal > tbody > tr > td {
  padding: 10px 40px 10px 0;
}

.ngx-table__table--normal > thead > tr > th:last-child {
  padding: 10px 6px;
}

.ngx-table__table--normal > tbody > tr > td:last-child {
  padding: 10px 6px;
}

.ngx-table {
  .btn {
    i {
      font-size: 1rem;
    }
  }
}

.deleteImg-button {
  float: right;
  width: 40px;
  height: 35px;
  border: transparent;
}

.edit-disabled {
  background-color: variables.$other-medium-gray !important;
  opacity: 0.4 !important;
}

.dropdownDossier {
  margin-right: 28% !important;
}

.icon-container {
  margin-right: 10px;
  margin-left: 10px;
}

.description {
  border-radius: 5px;
  border: 1px solid #dddddd;
  height: 40px;
  width: 100%;
  resize: none;
  overflow-y: hidden;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.ngx-table__horizontal-scroll {
  overflow-y: hidden;
}

.dropdown-item:active {
  background-color: variables.$primary-color;
}

.bg-purple {
  background-color: #f05989 !important;
}

.red-color {
  color: #ff0101 !important;
  font-weight: 600;
  cursor: pointer;
}

.black-color {
  color: black !important;
  cursor: pointer;
}

.green-color {
  color: #6bd098 !important;
  font-weight: 600;
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: space-between;

  h4 {
    margin-top: 0;
  }
}

ngb-tooltip-window.bs-tooltip-top .tooltip-inner {
  margin: auto;
}

ngb-tooltip-window.bs-tooltip-start {
  padding-right: 1px;
}

ngb-tooltip-window.bs-tooltip-start .tooltip-inner {
  margin-left: auto;
}

.ngx-dropdown-list-container {
  max-height: 300px;
}

.custom-class .tooltip-inner {
  background-color: variables.$dark-color;
  margin-left: 15px;
  margin-top: 15px;
  font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
  border-radius: 0;
  height: 40px;
  padding-top: 10px;
  min-width: 150px;
}

.custom-class.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: variables.$dark-color;
}

.custom-class.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: variables.$dark-color;
}

.custom-class.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: variables.$dark-color;
}

.custom-class.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: variables.$dark-color;
}

.ngx-table__table--tiny > tbody > tr > td {
  max-width: 350px;
  overflow-x: hidden;
}

.btn.show {
  background-color: #f6f7f9 !important;
}

.card label {
  font-size: 14px;
}

.notes-modal-size .modal-dialog {
  max-width: 1300px !important;
}

.position {
  padding: 5px 0;
  span {
    cursor: pointer;
  }
}

.balance-modal-size .modal-dialog {
  max-width: 500px !important;
  max-height: 200px !important;
}
