@use '../variables';
@use 'transparency';

// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
  background-color: $btn-color;

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover {
    background-color: $btn-states-color !important;
    color: variables.$white-color !important;
    box-shadow: none !important;
  }

  &:not([data-action]):hover {
    box-shadow: none;
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $btn-color;
      border-color: $btn-color;
    }
  }

  // btn-neutral style
  @if $btn-color == variables.$white-color {
    color: variables.$primary-color;

    &.btn-danger {
      color: variables.$danger-color;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: variables.$danger-states-color !important;
      }
    }

    &.btn-info {
      color: variables.$info-color !important;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: variables.$info-states-color !important;
      }
    }

    &.btn-warning {
      color: variables.$warning-color !important;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: variables.$warning-states-color !important;
      }
    }

    &.btn-success {
      color: variables.$success-color !important;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: variables.$success-states-color !important;
      }
    }

    &.btn-default {
      color: variables.$default-color !important;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: variables.$default-states-color !important;
      }
    }

    &.active,
    &:active,
    &:active:focus,
    &:active:hover,
    &.active:focus,
    &.active:hover,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle:focus,
    .show > &.dropdown-toggle:hover {
      background-color: variables.$white-color !important;
      color: variables.$primary-states-color !important;
      box-shadow: none !important;
    }

    &:hover,
    &:focus {
      color: variables.$primary-states-color !important;

      &:not(.nav-link) {
        box-shadow: none;
      }
    }
  } @else {
    color: variables.$white-color;
  }

  &.btn-simple {
    color: $btn-color;
    border-color: $btn-color;

    &:hover,
    &:focus,
    &:active {
      background-color: variables.$transparent-bg;
      color: $btn-states-color;
      border-color: $btn-states-color;
      box-shadow: none;
    }
  }

  &.btn-link {
    color: $btn-color;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: variables.$transparent-bg;
      color: $btn-states-color;
      text-decoration: none;
      box-shadow: none;
    }
  }
}

@mixin btn-outline-styles($btn-color, $btn-states-color) {
  background: variables.$transparent-bg;
  border: 2px solid $btn-color !important;
  color: $btn-color;
  @include transparency.opacity(1);

  &:hover,
  &:focus,
  &:active,
  &:focus:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $btn-color !important;
    color: variables.$fill-font-color !important;
    border-color: $btn-color !important;
    .caret {
      border-top-color: variables.$fill-font-color !important;
    }
  }

  .caret {
    border-top-color: variables.$white-color !important;
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: variables.$transparent-bg !important;
      border-color: $btn-color !important;
    }
  }
}

@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border) {
  font-size: $font-size;
  border-radius: $border;
  padding: $padding-vertical $padding-horizontal;

  &.btn-simple {
    padding: $padding-vertical - 1 $padding-horizontal - 1;
  }
}

@mixin rotate-180() {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
