/*!

=========================================================
* Paper Dashboard Angular - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@use 'paper-dashboard/variables';
@use 'paper-dashboard/mixins';

// Plugins CSS
@use 'paper-dashboard/plugins/plugin-animate-bootstrap-notify';
@use 'paper-dashboard/plugins/plugin-perfect-scrollbar';

// Core CSS
@use 'paper-dashboard/buttons';
@use 'paper-dashboard/inputs';
@use 'paper-dashboard/typography';
@use 'paper-dashboard/misc';
// @use "paper-dashboard/checkboxes-radio";

// components
@use 'paper-dashboard/navbar';
@use 'paper-dashboard/page-header';
// @use "paper-dashboard/dropdown";
@use 'paper-dashboard/alerts';
@use 'paper-dashboard/images';
@use 'paper-dashboard/nucleo-outline';
// @use "paper-dashboard/tables";
@use 'paper-dashboard/sidebar-and-main-panel';
@use 'paper-dashboard/footers';
@use 'paper-dashboard/fixed-plugin';

// cards
@use 'paper-dashboard/cards';
@use 'paper-dashboard/cards/card-plain';
@use 'paper-dashboard/cards/card-chart';
@use 'paper-dashboard/cards/card-user';
@use 'paper-dashboard/cards/card-map';
@use 'paper-dashboard/cards/card-stats';

// angular differences
@use 'paper-dashboard/angular/plugin-ngx-toastr';
@use 'paper-dashboard/angular/sidebar-and-main-panel' as sidebar-and-main-panel2;
@use 'paper-dashboard/angular/dropdown';
@use 'paper-dashboard/angular/inputs' as inputs2;
@use 'paper-dashboard/angular/fixed-plugin' as fixed-plugin2;

@use 'paper-dashboard/responsive';
